import React from "react"
import PropTypes from "prop-types"
import useInstagramFeed from "../../hooks/useInstagramFeed"
import { v4 as uuidv4 } from "uuid"
import ResponsiveImage from "../UI/ResponsiveImage/ResponsiveImage"
import { useGlobalContext } from "../../context/GlobalContext"
import FeedCard from "./FeedCard"

const InstagramFeedSection = props => {
    const {
        style,
        titleLogo,
        title,
        description,
        instagramBusinessAccountId,
        instagramBusinessAccessToken,
        latestPostsCount,
    } = props
    const { labels } = useGlobalContext()
    const [posts, loading, error] = useInstagramFeed({
        businessAccountId: instagramBusinessAccountId,
        accessToken: instagramBusinessAccessToken,
        count: latestPostsCount,
    })

    if (error) return <></>
    return (
        <div className={style?.wrapper}>
            <div className={style?.container}>
                <div className={style?.copyWrapper}>
                    <div className={style?.titleWrapper}>
                        {titleLogo && (
                            <ResponsiveImage
                                contentfulImagesComponent={{
                                    altText: titleLogo?.title,
                                    imageDesktop: titleLogo,
                                }}
                            />
                        )}
                        {title && <h2 className={style?.title}>{title}</h2>}
                    </div>
                    {description && <p className={style?.description}>{description}</p>}
                </div>
                {loading && <p className={style?.loading}>{labels?.instagramFeed?.loading || "Loading..."}</p>}
                <div className={style?.postsWrapper}>
                    {!loading && !error && posts.map(post => <FeedCard post={post} style={style} key={uuidv4()} />)}
                </div>
            </div>
        </div>
    )
}

InstagramFeedSection.propTypes = {
    style: PropTypes.object,
    titleLogo: PropTypes.object,
    title: PropTypes.string,
    description: PropTypes.string,
    instagramBusinessAccountId: PropTypes.string,
    instagramBusinessAccessToken: PropTypes.string,
    latestPostsCount: PropTypes.number,
}

export default InstagramFeedSection
