export const defaultStyle = {
    wrapper: "relative",
}

export const PLP_BANNER = {
    wrapper: "relative h-[292px] md:h-[320px] lg:h-[400px] xl:h-[480px]",
    imgWrapper: "absolute inset-0",
    img: "w-full h-full object-cover",
    contentWrapper: "relative section-wrapper flex items-center justify-center h-full",
    underlineTextWrapper: "hidden",
    headingWrapper: "headline2 title-shadow text-white text-center",
}
export const RLP_HERO = {
    wrapper: "relative h-[300px] md:h-[320px] lg:h-[440px] xl:h-[560px]",
    imgWrapper: "absolute inset-0",
    img: "w-full h-full object-cover",
    contentWrapper: "relative section-wrapper flex items-center justify-center h-full",
    underlineTextWrapper: "hidden",
    headingWrapper: "headline2 title-shadow text-white text-center",
}
