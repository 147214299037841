export const defaultStyle = {
    wrapper: "hidden",
}

export const Homepage = {
    wrapper: "relative bg-red",
    container: "relative section-wrapper flex flex-col items-center gap-8 py-[94px] md:py-[120px]",
    title: "headline2 text-black text-center",
    sliderWrapper: "w-full mb-2 lg:mb-0",
    cta: "button-primary",
}
