export const defaultStyle = {
    wrapper: "hidden",
}

export const Homepage = {
    wrapper: "relative bg-red",
    container: "section-wrapper flex flex-col items-center gap-8 py-[94px] md:py-[120px]",
    title: "headline2 text-white title-shadow text-center",
    sliderWrapper: "w-full",
    searchTitle: "headline3 text-white title-shadow text-center",
    searchWrapper: "relative self-stretch",
    cta: "button-link text-center",
}
