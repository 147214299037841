const query = (id, isPreview) => `{
  heroAnchorsSection(id: "${id}", preview: ${isPreview}) {
    internalTitle
    title
    subtitle
    anchorsCollection(limit: 6) {
        items {
            label
            title
            url
            target
            scrollToElementId
            gaEventClass
            gaEventLabel
        }
    }
  }
}
`

module.exports = { query }
