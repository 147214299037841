const fragment = `
    json
    links {
        entries {
            inline {
                sys {
                    id
                }
                ... on Link {
                    label
                    url
                    target
                }
            }
        }
    }    
`
module.exports = { fragment }
