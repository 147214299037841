import React from "react"
import PropTypes from "prop-types"
import Button, { variants, iconNames } from "../UI/Button/Button"
import BeigeOverlay from "../UI/BeigeOverlay/BeigeOverlay"

import { useRecipe } from "../../context/RecipeProvider"
import { v4 as uuidv4 } from "uuid"
import RecipeCard from "../UI/RecipeCard/RecipeCard"
import { labels as recipeLabels } from "../../constants/recipe.constants"
const RecipeListSection = props => {
    const { style, loadMoreButtonLabel } = props
    const { searchResults, isLoading, handleHitsPerPageIncrement, hitsPerPage } = useRecipe()

    const loadMoreHandler = () => {
        handleHitsPerPageIncrement()
    }
    return (
        <div className={style?.wrapper}>
            <BeigeOverlay />
            <div className={style?.container}>
                <div className={style?.cardsWrapper}>
                    {!isLoading && searchResults?.hits?.length === 0 && <span>{recipeLabels.notFound}</span>}
                    {searchResults?.hits?.map((recipe, index) => (
                        <RecipeCard key={uuidv4()} recipe={recipe} variant="RecipePage" />
                    ))}
                </div>
                {isLoading && <span>{recipeLabels.loading}</span>}
                {searchResults?.error && <span>{recipeLabels.error}</span>}
                {hitsPerPage < searchResults?.nbHits && !isLoading && (
                    <Button variant={variants.secondary} icon={iconNames.Refresh} onClick={loadMoreHandler}>
                        {loadMoreButtonLabel || recipeLabels.loadMoreDefault}
                    </Button>
                )}
            </div>
        </div>
    )
}

RecipeListSection.propTypes = {
    style: PropTypes.object,
    loadMoreButtonLabel: PropTypes.string,
}

export default RecipeListSection
