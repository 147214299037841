import React from "react"
import PropTypes from "prop-types"
import RecipesSearchInput from "../RecipesSearchInput/RecipesSearchInput"
import { useRecipe } from "../../context/RecipeProvider"
import { v4 as uuidv4 } from "uuid"
import { pageTypes } from "../../constants/pageType.constants"
import SelectWrapper from "./SelectWrapper"
import { gaEventClasses } from "../../constants/gtm.constants"

const RecipeFiltersSection = props => {
    const { style, title, searchLabel, clearFiltersLabel, recipeFilters } = props
    const { handleResetAll, isLoading } = useRecipe()

    const clearFiltersHandler = e => {
        handleResetAll()
    }

    return (
        <div className={style?.wrapper}>
            <div className={style?.container}>
                <h3 className={style?.title}>{title}</h3>
                <div className={style?.searchWrapper}>
                    <RecipesSearchInput label={searchLabel} icon="Search" pageType={pageTypes.recipeDetail} />
                </div>
                <div className={`${style?.dropdownsWrapper} ${isLoading ? "pointer-events-none opacity-80" : ""}`}>
                    {Object.keys(recipeFilters).map(filter => {
                        return (
                            <div key={uuidv4()} className={style?.selectWrapper}>
                                <SelectWrapper recipeFilters={recipeFilters} filter={filter} />
                            </div>
                        )
                    })}
                </div>
                <div>
                    <button
                        type="button"
                        aria-label={clearFiltersLabel || "Clear Filters"}
                        className={`${style?.cta} ${gaEventClasses.button_click}`}
                        onClick={clearFiltersHandler}
                        data-action-detail={clearFiltersLabel || "Clear Filters"}
                    >
                        {clearFiltersLabel || "Clear Filters"}
                    </button>
                </div>
            </div>
        </div>
    )
}

RecipeFiltersSection.propTypes = {
    style: PropTypes.object,
    title: PropTypes.string,
    searchLabel: PropTypes.string,
    clearFiltersLabel: PropTypes.string,
    recipeFilters: PropTypes.object,
}

export default RecipeFiltersSection
