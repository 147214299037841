import { useEffect, useState } from "react"
import axios from "axios"

const buildUrl = (businessAccountId, accessToken, count) => {
    return `https://graph.facebook.com/v17.0/${businessAccountId}/media?fields=media_url%2Cmedia_type%2Cpermalink%2Cthumbnail_url%2Ccaption&limit=${count}&access_token=${accessToken}`
}

const useInstagramFeed = ({ businessAccountId, accessToken, count = 8 }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [posts, setPosts] = useState([])

    useEffect(() => {
        const controller = new AbortController()
        const signal = controller.signal

        const fetchInstagramFeed = async () => {
            setLoading(true)
            setError(null)
            try {
                const url = buildUrl(businessAccountId, accessToken, count)
                const response = await axios.get(url, { signal })
                setPosts(response.data.data)
            } catch (error) {
                if (!signal.aborted) {
                    console.log("Error fetching posts from Instagram. Error: ", error)
                    setError(error.message)
                }
            } finally {
                if (!signal.aborted) {
                    setLoading(false)
                }
            }
        }

        fetchInstagramFeed()

        return () => {
            controller.abort()
        }
    }, [businessAccountId, accessToken, count])

    return [posts, loading, error]
}
export default useInstagramFeed
