export const defaultStyle = {
    wrapper: "relative bg-red",
    container:
        "relative section-wrapper py-16 md:py-20 lg:py-24 xlg:py-[120px] flex flex-col items-center gap-[28px] lg:gap-12",
    copyWrapper: "flex flex-col gap-2",
    titleWrapper:
        "flex flex-col gap-0 items-center text-center img-descenders:max-w-[120px] img-descenders:w-[120px] md:img-descenders:max-w-[200px] md:img-descenders:w-[200px] lg:img-descenders:max-w-[240px] lg:img-descenders:w-[240px]",
    title: "headline2 text-white title-shadow",
    description: "body-l font-medium text-center text-white",
    loading: "text-white",
    postsWrapper: "grid grid-cols-2 gap-5 lg:grid-cols-4 w-full max-w-[524px] lg:max-w-[unset]",
    feedCard:
        "translate-y-0 hover:translate-y-[-4px] transition-all duration-300 ease-in-out relative aspect-square recipe-card-filter-default rounded-[5px] border-[1px] border-black max-w-[252px] lg:max-w-[unset] ",
    feedCardImage: "object-cover object-center rounded-[5px] border-[6px] border-white w-full h-full",
}
