import React from "react"
import PropTypes from "prop-types"
import { get } from "../../helpers/utils"
import Typography from "../UI/Typography/Typography"
import Link from "../UI/Link/Link"
import Image from "../UI/Image/Image"
import * as styles from "./ImageLayout-tw-styles"
import { gaEventClasses } from "../../constants/gtm.constants"

const ImageLayout = props => {
    const { heading, description, imagesCollection } = props
    const { cardsCollection = {} } = imagesCollection
    const style = styles.defaultStyle
    const images = get(cardsCollection, "items", [])

    return (
        <div className={style?.layoutContainer}>
            <div className={style?.textContainer}>
                {heading && <Typography content={heading} className={style?.heading} />}
                {description && (
                    <Typography
                        content={description}
                        className={style?.description}
                        br_allowed={true}
                        allow_br_tag={true}
                    />
                )}
            </div>
            <ul className={style?.list}>
                {images?.length &&
                    images.map((item, index) => {
                        const { image, link, gaEventClass, title = "" } = item
                        return (
                            <li className={style?.listItem} key={index}>
                                <Link
                                    className={style?.anchor}
                                    {...link}
                                    gaEventClass={gaEventClasses.image_click}
                                    gaEventLabel={title}
                                    image={image}
                                >
                                    <Image className={style?.imgMain} contentfulImage={image} />
                                </Link>
                            </li>
                        )
                    })}
            </ul>
        </div>
    )
}

ImageLayout.propTypes = {
    heading: PropTypes.string,
    description: PropTypes.string,
    imagesCollection: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default ImageLayout
