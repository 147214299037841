const query = (id, isPreview) => `
{
    form(id: "${id}", preview: ${isPreview}) {
        heading
        description
        jsonEditor
  }
}
`
module.exports = { query }
