export const defaultStyle = {
    wrapper: "relative",
}

export const Homepage = {
    wrapper: "relative flex flex-col gap-4 w-[260px] md:mx-auto",
    imageWrapper: "relative w-full h-auto",
    img: "object-cover w-full h-full",
    title: "text-black text-center subtitle-big px-4 line-clamp-2 min-h-[50px]",
}
export const ProductListingPage = {
    wrapper: "relative flex flex-col gap-4 w-[169px] md:w-[342px] lg:w-[270px] xlg:w-[345px] justify-between",
    imageWrapper: "relative w-full h-auto md:px-[86.5px] lg:px-[25px]",
    img: "object-cover w-full h-full",
    title: "text-black text-center subtitle-small line-clamp-2 min-h-[40px] lg:min-h-[50px] lg:subtitle-big",
}
