import React from "react"
import { StaticImage } from "gatsby-plugin-image"

function BeigeOverlay() {
    return (
        <div className="absolute -top-[1px] left-0 w-full bottom-0 pointer-events-none z-[0] bg-beige">
            <StaticImage
                src="../../../assets/Royal-beige-bg-mobile.png"
                alt="Royal-beige-bg"
                className="w-full h-full object-cover inline-block md:hidden [&_img]:object-top"
                imgStyle={{ transition: "none" }}
            />
            <StaticImage
                src="../../../assets/Royal-beige-bg.png"
                alt="Royal-beige-bg"
                className="w-full h-full object-cover hidden md:inline-block [&_img]:object-top"
                imgStyle={{ transition: "none" }}
            />
        </div>
    )
}

export default BeigeOverlay
