const query = (isPreview, locale) => `{
  headerCollection(preview: ${isPreview}, limit: 1, locale: "${locale}") {
    items {
      logo {
        url
        title
        description
        width
        height
      }
      navigationCollection(limit: 10) {
        items {
            label
            title
            url
            target
            scrollToElementId
            gaEventClass
            gaEventLabel
        }
      }
    }
  }
}
`

module.exports = { query }
