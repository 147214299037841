export const defaultStyle = {
    wrapper: "relative",
    imgTxtContainer:
        "flex flex-wrap lg:flex-nowrap items-center justify-between max-w-full pb-16 my-0 mx-auto top-[50%] translate-y-[-50%] w-[650px] md:left-[90px] px-[40px] zMinus",
    imgWrapper: "lg:mx-[20px] lg:my-auto mx-auto mt-0 mb-10",
    textImg: "w-full ",
    textContent: "text-lg leading-7 text-center w-[354px] m-w-[354px] text-[#7f7f7f] mx-auto my-auto",
    textHead: "text-[#00358e] font-bold	mx-auto",
    textDesc: "",
    textLink: "",
    imgBtn: "",
}

export const PAGE_404 = {
    wrapper: "wrapper relative bg-red py-[64px] md:px-[120px]",
    imgTxtContainer: "imgTxtContainer max-w-[1440px] flex flex-col m-auto items-center",
    imgWrapper: "imgWrapper w-80",
    textImg: "textImg w-full ",
    textWrpr: "textWrpr",
    textContent: "textContent",
    textHead: "textHead",
    textDesc: 
    "textDesc text-center body-m text-white mt-10 mb-6 [&>h3]:headline5 [&>h3]:leading-[0.4] [&>h3]:footer-navigation-shadow [&>p]:mt-4",
    textLink: "textLink button-primary",
    imgBtn: "imgBtn",
}
