const query = (id, isPreview) => `{
  instagramFeedSection(id: "${id}", preview: ${isPreview}) {
    internalTitle
    titleLogo {
      url
      title
      description
      width
      height
    }
    title
    description
    instagramBusinessAccountId
    instagramBusinessAccessToken
    latestPostsCount
  }
}
`

module.exports = { query }
