import React from "react"
import PropTypes from "prop-types"
import Link from "../UI/Link/Link"
import RecipeCardsSlider from "../RecipeCardsSlider/RecipeCardsSlider"
import RecipesSearchInput from "../RecipesSearchInput/RecipesSearchInput"
import { pageTypes } from "../../constants/pageType.constants"

const FeaturedRecipesSection = props => {
    const { style, title, searchTitle, searchLabel, variant, cta, recipesFromApi = [] } = props
    if (recipesFromApi.length === 0) {
        console.log("No recipes found for the given Recipe Ids.")
    }
    return (
        <div className={style?.wrapper}>
            <div className={style?.container}>
                {recipesFromApi.length > 0 && (
                    <>
                        <h2 className={style?.title}>{title}</h2>
                        <div className={style?.sliderWrapper}>
                            <RecipeCardsSlider recipes={recipesFromApi} variant={variant} />
                        </div>
                    </>
                )}
                <h2 className={style?.searchTitle}>{searchTitle}</h2>
                <div className={style?.searchWrapper}>
                    <RecipesSearchInput label={searchLabel} icon="Search" pageType={pageTypes.staticPage} />
                </div>
                <div>
                    <Link {...cta} className={style?.cta} />
                </div>
            </div>
        </div>
    )
}

FeaturedRecipesSection.propTypes = {
    style: PropTypes.object,
    title: PropTypes.string,
    searchTitle: PropTypes.string,
    searchLabel: PropTypes.string,
    recipeIdsCollection: PropTypes.object,
    variant: PropTypes.string,
    cta: PropTypes.object,
    recipesFromApi: PropTypes.array,
}

export default FeaturedRecipesSection
