import React from "react"
import PropTypes from "prop-types"
import Link from "../UI/Link/Link"
import ProductCardsSlider from "../ProductCardsSlider/ProductCardsSlider"
import BeigeOverlay from "../UI/BeigeOverlay/BeigeOverlay"

const FeaturedProductsSection = props => {
    const { style, title, variant, cta, productsFromApi = [] } = props
    if (productsFromApi.length === 0) {
        console.log("No products found for the given Product Ids.")
    }

    return (
        productsFromApi.length > 0 && (
            <div className={style?.wrapper}>
                <BeigeOverlay />
                <div className={style?.container}>
                    <h2 className={style?.title}>{title}</h2>
                    <div className={style?.sliderWrapper}>
                        <ProductCardsSlider products={productsFromApi} variant={variant} />
                    </div>
                    <div>
                        <Link {...cta} className={style?.cta} />
                    </div>
                </div>
            </div>
        )
    )
}

FeaturedProductsSection.propTypes = {
    style: PropTypes.object,
    title: PropTypes.string,
    productIdsCollection: PropTypes.object,
    variant: PropTypes.string,
    cta: PropTypes.object,
    productsFromApi: PropTypes.array,
}

export default FeaturedProductsSection
