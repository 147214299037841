const query = (id, isPreview) => `{
  productCategorySection(id: "${id}", preview: ${isPreview}) {
    internalTitle
    categoryId
    variant
  }
}
`

module.exports = { query }
