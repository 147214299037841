import React, { useState } from "react"
import PropTypes from "prop-types"
import { v4 as uuidv4 } from "uuid"
import BeigeOverlay from "../UI/BeigeOverlay/BeigeOverlay"
import ProductCard from "../UI/ProductCard/ProductCard"
import Button, { variants, iconNames } from "../UI/Button/Button"
import { labels as productLabels } from "../../constants/product.constants"
import { useGlobalContext } from "../../context/GlobalContext"
import { gaEventClasses } from "../../constants/gtm.constants"

const ProductCategorySection = props => {
    const { style, variant, categoryId, productsFromApi = [] } = props
    const { labels } = useGlobalContext()
    const [visibleProducts, setVisibleProducts] = useState(4)

    const onLoadMore = () => {
        setVisibleProducts(prev => prev + 4)
    }
    return (
        <div className={style?.wrapper}>
            <BeigeOverlay />
            <div className={style?.container}>
                {productsFromApi.length === 0 && (
                    <p className="text-center">
                        No products found in database with provided id ({categoryId}).
                        <br />
                        Please check Contenful and make sure Category Id is matching with at least 1 product.
                    </p>
                )}
                <div className={style?.cardsWrapper}>
                    {productsFromApi.length > 0 &&
                        productsFromApi
                            .slice(0, visibleProducts)
                            .map(product => (
                                <ProductCard key={uuidv4()} product={product} variant="ProductListingPage" />
                            ))}
                </div>
                {productsFromApi.length > 0 && productsFromApi.length > visibleProducts && (
                    <div className={style?.loadMoreWrapper}>
                        <Button
                            variant={variants.secondary}
                            icon={iconNames.Refresh}
                            onClick={onLoadMore}
                            dataActionDetail={labels?.loadMoreProducts}
                            gaEventClass={gaEventClasses.button_click}
                        >
                            {labels?.loadMoreProducts || productLabels.PLP_LOAD_MORE_DEFAULT}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    )
}

ProductCategorySection.propTypes = {
    style: PropTypes.object,
    variant: PropTypes.string,
    categoryId: PropTypes.string,
    productsFromApi: PropTypes.array,
}

export default ProductCategorySection
