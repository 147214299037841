import React from "react"
import PropTypes from "prop-types"
import HomeHeroSlider from "./HomeHeroSlider/HomeHeroSlider"

export const SLIDER_VARIANTS = {
    HOME_HERO_SLIDER: "HOME_HERO_SLIDER"
}

const HeroSlider = props => {
    const renderHeroSlider = (sliderInfo) => {
        switch (sliderInfo?.variant) {
            case SLIDER_VARIANTS.HOME_HERO_SLIDER:
                return <HomeHeroSlider {...sliderInfo} />
            default:
                return <div>Default slider</div>
        }
    }

    return props.variant ? renderHeroSlider(props) : null
}

HeroSlider.propTypes = {
    style: PropTypes.object,
    variant: PropTypes.string,
    slidesCollection: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    sliderSettings: PropTypes.object,
}

export default HeroSlider
