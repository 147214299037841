import React from "react"
import PropTypes from "prop-types"
import Typography from "../UI/Typography/Typography"
import Link from "../UI/Link/Link"
import ResponsiveImage from "../UI/ResponsiveImage/ResponsiveImage"

const ImageWithText = props => {
    const {
        heading,
        description,
        images,
        link,
        style,
        isReversed,
        headingIsLink,
        hasContent,
        elementId = "",
    } = { ...props }

    const desc = typeof description === "object" ? description?.description : description

    return (
        <div className={style?.wrapper}>
            <div className={`${style?.imgTxtContainer} ${isReversed ? style?.reverse : ""}`}>
                <div className={style?.imgWrapper}>
                    <span className={style?.gradBg}></span>
                    {!!images && (
                        <div className={style?.subimgWrpr}>
                            <ResponsiveImage contentfulImagesComponent={images} className={style?.textImg} />
                        </div>
                    )}
                </div>
                <div className={`${hasContent ? style?.textContent : style?.textWrpr}`} id={elementId}>
                    {heading ? (
                        headingIsLink ? (
                            <Link {...link} className={style?.textLink}>
                                {heading}
                            </Link>
                        ) : (
                            <Typography className={style?.textHead} content={heading} />
                        )
                    ) : null}
                    {desc && <Typography content={desc} className={style?.textDesc} />}

                    {link && <Link {...link} className={style?.textLink} />}
                </div>
            </div>
        </div>
    )
}

export default ImageWithText

ImageWithText.propTypes = {
    heading: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    headingIsLink: PropTypes.bool,
    link: PropTypes.object,
    foregroundImg: PropTypes.object,
    style: PropTypes.object,
    isReversed: PropTypes.bool,
    hasContent: PropTypes.bool,
    backgroundImg: PropTypes.object,
    elementId: PropTypes.string,
}
