const query = (id, isPreview) => `{
  recipeFiltersSection(id: "${id}", preview: ${isPreview}) {
    internalTitle
    title
    searchLabel
    clearFiltersLabel
  }
}
`

module.exports = { query }
