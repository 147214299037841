import React from "react"
import PropTypes from "prop-types"
import Typography from "../UI/Typography/Typography"

export const ListingSteps = props => {
    const { recipeSteps, heading, subheading, style } = props

    return (
        <div className={style?.receipeMainWrpr}>
            <div className={style?.headingWrpr}>
                <h1 className={style?.receipeHeading}>{heading}</h1>
                <h2 className={style?.receipeSubheading}>{subheading}</h2>
            </div>
            <div className="flex w-full">
                <ol className={style?.olClass}>
                    {recipeSteps?.length &&
                        recipeSteps.map((recipeStep, index) => (
                            <li className={style?.stepsContainer} key={index}>
                                <h3 className={style?.roundedHeading}>{index + 1}</h3>
                                <Typography
                                    content={recipeStep?.PreparationDescriptionItem}
                                    className={style?.liDescription}
                                />
                            </li>
                        ))}
                </ol>
            </div>
        </div>
    )
}

ListingSteps.propTypes = {
    recipeHeading: PropTypes.string,
    heading: PropTypes.string,
    subheading: PropTypes.string,
    recipeSteps: PropTypes.array,
    style: PropTypes.object,
}
