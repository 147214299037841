import React, { createContext, useContext, useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { labels as recipeLabels } from "../constants/recipe.constants"
import { getRecipeSearchResults } from "../adapters/algolia-adapter"
import { debounce } from "lodash"
import { getLangAttribute } from "../constants/locale.constants"
import { gaEventClasses } from "../constants/gtm.constants"
import { useGlobalContext } from "./GlobalContext"

const FILTERS_INITIAL_STATE = {
    cookingType: "",
    occasion: "",
    special: "",
}

const RecipeContext = createContext()
export const useRecipe = () => {
    const context = useContext(RecipeContext)
    if (!context) {
        throw new Error("useRecipe must be used within a RecipeProvider")
    }
    return context
}

export const RecipeProvider = ({ recipeFilters, children }) => {
    const { language } = useGlobalContext()
    const availableSubCategoriesInitial = Object.keys(recipeFilters).reduce((acc, curr) => {
        acc[curr] = {}
        recipeFilters[curr].forEach(item => {
            acc[curr][item.CategoryId] = true
        })
        return acc
    }, {})

    const [query, setQuery] = useState("")
    const [searchResults, setSearchResults] = useState({})
    const [hitsPerPage, setHitsPerPage] = useState(recipeLabels[language]?.HPP_DEFAULT || 8)

    const [filters, setFilters] = useState(FILTERS_INITIAL_STATE)
    const [availableSubCategories, setAvailableSubCategories] = useState(availableSubCategoriesInitial)
    const [isLoading, setIsLoading] = useState(false)

    const fetchRecipesAndSetSubCategoryResults = useCallback(async () => {
        try {
            setIsLoading(true)
            const res = await getRecipeSearchResults(
                {
                    search: query,
                    hitsPerPage,
                    cookingType: filters.cookingType,
                    occasion: filters.occasion,
                    special: filters.special,
                    analytics: true,
                },
                language
            )
            setAvailableSubCategories(prevSubCategories => {
                let newSubCategories = {}
                for (let subcategory in prevSubCategories) {
                    newSubCategories[subcategory] = {}
                    for (let item in prevSubCategories[subcategory]) {
                        newSubCategories[subcategory][item] = false
                    }
                }
                for (let facet in res.facets) {
                    let subcategory = facet.split(".")[0]
                    if (newSubCategories[subcategory]) {
                        for (let item in res.facets[facet]) {
                            newSubCategories[subcategory][item] = true
                        }
                    }
                }
                return newSubCategories
            })

            setSearchResults(res)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        } finally {
            setIsLoading(false)
        }
    }, [query, hitsPerPage, filters, language])

    const debouncedFetchRecipesAndSetSubCategoryResults = useCallback(
        debounce(fetchRecipesAndSetSubCategoryResults, 50), // 50ms debounce
        [fetchRecipesAndSetSubCategoryResults]
    )

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const detectedLanguage = getLangAttribute(window.location.pathname)
        const newQuery = urlParams.get("query") || ""
        const newHitsPerPage = Number(urlParams.get("hitsPerPage")) || recipeLabels[detectedLanguage]?.HPP_DEFAULT || 8
        const newCookingType = urlParams.get("cookingType") || ""
        const newOccasion = urlParams.get("occasion") || ""
        const newSpecial = urlParams.get("special") || ""
        setQuery(newQuery)
        setHitsPerPage(newHitsPerPage)
        setFilters(prevFilters => ({
            ...prevFilters,
            cookingType: newCookingType,
            occasion: newOccasion,
            special: newSpecial,
        }))
    }, [])

    useEffect(() => {
        const urlParams = new URLSearchParams()
        if (query) urlParams.set("query", query)
        if (filters.cookingType) urlParams.set("cookingType", filters.cookingType)
        if (filters.occasion) urlParams.set("occasion", filters.occasion)
        if (filters.special) urlParams.set("special", filters.special)
        if (hitsPerPage !== recipeLabels[language].HPP_DEFAULT) urlParams.set("hitsPerPage", hitsPerPage)
        const paramString = urlParams.toString()
        const detectedLanguage = getLangAttribute(window.location.pathname)
        const localizedSlug = `/${detectedLanguage}${recipeLabels[detectedLanguage]?.slug || recipeLabels["es"].slug}`
        const currentPathname = window.location.pathname

        const isRecetasPage = currentPathname.startsWith(localizedSlug)
        if (
            query !== "" ||
            hitsPerPage !== recipeLabels[language].HPP_DEFAULT ||
            filters.cookingType !== "" ||
            filters.occasion !== "" ||
            filters.special !== ""
        ) {
            navigate(paramString.length > 0 ? `${localizedSlug}?${paramString}` : `${localizedSlug}`, {
                replace: isRecetasPage,
            })
        }
        if (paramString.trim() === "" && isRecetasPage) {
            navigate(`${localizedSlug}`, { replace: isRecetasPage })
        }
    }, [query, hitsPerPage, filters, language])

    useEffect(() => {
        debouncedFetchRecipesAndSetSubCategoryResults()
        return () => {
            debouncedFetchRecipesAndSetSubCategoryResults.cancel()
        }
    }, [debouncedFetchRecipesAndSetSubCategoryResults])

    const handleSearchInputChange = newQuery => {
        setQuery(newQuery)
    }

    const handleHitsPerPageIncrement = () => {
        setHitsPerPage(prevHitsPerPage => prevHitsPerPage + 4)
    }

    const handleFilterChange = (category, selectedOption) => {
        const { value, label } = selectedOption
        // Toggles the filter
        setFilters(prevFilters => {
            if (prevFilters[category] === value) {
                return {
                    ...prevFilters,
                    [category]: "",
                }
            }
            if (window?.dataLayer) {
                window.dataLayer.push({
                    event: gaEventClasses.filter_click,
                    GAeventCategory: gaEventClasses.informational_action,
                    GAeventAction: gaEventClasses.filter_click,
                    GAeventLabel: label,
                    GAeventValue: 0,
                    GAeventNonInteraction: false,
                })
            }
            return {
                ...prevFilters,
                [category]: value,
            }
        })
    }
    const handleClearFilters = () => {
        setFilters(FILTERS_INITIAL_STATE)
    }

    const handleResetAll = () => {
        setQuery("")
        setFilters(FILTERS_INITIAL_STATE)
    }
    const getOptionsExceptCurrentFilter = async filter => {
        const fetchOptions = {
            search: query,
            hitsPerPage,
            cookingType: filters.cookingType,
            occasion: filters.occasion,
            special: filters.special,
            analytics: false,
        }
        fetchOptions[filter] = ""
        const res = await getRecipeSearchResults(fetchOptions, language)
        const { facets } = res
        const excludedSubCategories = { ...availableSubCategories }
        for (let facet in facets) {
            let subcategory = facet.split(".")[0]
            if (excludedSubCategories[subcategory]) {
                for (let item in facets[facet]) {
                    excludedSubCategories[subcategory][item] = true
                }
            }
        }
        return excludedSubCategories
    }

    const recipeState = {
        isLoading,
        query,
        searchResults,
        filters,
        hitsPerPage,
        availableSubCategories,
        handleSearchInputChange,
        handleFilterChange,
        handleHitsPerPageIncrement,
        handleClearFilters,
        handleResetAll,
        getOptionsExceptCurrentFilter,
    }

    return <RecipeContext.Provider value={recipeState}>{children}</RecipeContext.Provider>
}

RecipeProvider.propTypes = {
    children: PropTypes.node.isRequired,
    recipeFilters: PropTypes.object,
}
