const { fragment } = require("./ImageHeroBanner.fragment")

const query = (id, isPreview) => `
{
    imageHeroBanner(id: "${id}", preview: ${isPreview}) {
       ${fragment}
  }
}
`
module.exports = { query, fragment }
