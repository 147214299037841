import React, { useState } from "react"
import Button from "../Button/Button"
import PropTypes from "prop-types"
import ReCAPTCHA from "react-google-recaptcha"
import { validate, validateEmail } from "../../../helpers/FormValidations"
import * as styles from "./form-tw"
import Typography from "../Typography/Typography"
import RenderField from "../../../helpers/RenderField"
import ModalWrapper from "../../Wrappers/ModalWrapper/ModalWrapper"
import axios from "axios"
import { validString } from "../../../helpers/FormValidations"

const JOB_TITLE = ""
const JOB_TITLE_INPUT = ""
const MANDATORY = ""
const WORK_SECTOR = ""
const WORK_SECTOR_INPUT = ""
import { formSubmitObj, gaEventClasses } from "../../../constants/gtm.constants"
import LoaderIcon from "../../../assets/icons/LoaderIcon"

let minLenFields = ["firstName", "lastName"]
let checkboxList = ["confirmationCheckbox", "aggrementCheckbox"]
let mandateFields = {
    firstName: "",
    lastName: "",
    jobTitle: "",
    workSector: "",
    email: "",
    confirmationCheckbox: "",
    jobTitleInput: "",
    workSectorInput: "",
}
let orderedFieldList = {
    firstName: "",
    lastName: "",
    jobTitle: "",
    establishmentName: "",
    workSector: "",
    wholeSaler: "",
    email: "",
}

let orderedcheckboxList = {
    confirmationCheckbox: "",
    aggrementCheckbox: "",
}

const orderList = (list, actualList) => {
    Object.entries(list).map(([key]) => {
        list[key] = actualList[key]
        return list[key]
    })
}
const Form = props => {
    const { heading, jsonEditor, description, formSubmission } = props
    const siteKey = process.env.GATSBY_APP_SITE_KEY
    const formUrl = process.env.GATSBY_FORM_API
    const { fieldList, checkbox, submit, bookType, reCaptchaError, variant } = jsonEditor
    orderList(orderedFieldList, fieldList)
    orderList(orderedcheckboxList, checkbox)

    const [form, setForm] = useState(orderedFieldList)
    const [isAPIFailed, setIsAPIFailed] = useState(false)
    const [checkBox, setCheckBox] = useState(orderedcheckboxList)
    const [isLoading, setIsLoading] = useState(false)
    const [recaptchaValue, setRecaptchaValue] = useState("")
    const [recaptchaDone, setRecaptchaDone] = useState(false)
    const [ShowRecaptchaError, setShowRecaptchaError] = useState(false)
    const [showError, setShowError] = useState(false)
    const [isSubmitClicked, setIsSubmitClicked] = useState(false)
    const style = styles.formStyles
    const { formTitle, formDesc, formTitleWrap } = style

    function ValidateForm(orgObj, tempObj) {
        return Object.entries(orgObj).map(([key, value]) => {
            tempObj[key] = validate(value)
            setForm(field => ({
                ...field,
                [key]: {
                    ...field[key],
                    isValid: tempObj[key].isValid,
                },
            }))
            return tempObj[key].isValid
        })
    }

    function ValidateCheckBox(orgObj, tempObj) {
        return Object.entries(orgObj).map(([key, value]) => {
            tempObj[key] = validate(value)
            setCheckBox(field => ({
                ...field,
                [key]: {
                    ...field[key],
                    isValid: tempObj[key].isValid,
                },
            }))
            return tempObj[key].isValid
        })
    }

    function validateOtherInput(jobTitle, workSector) {
        const jobCheck = jobTitle.value == "6" && jobTitle.otherInput.value == "" ? false : true
        const workCheck = workSector.value == "10" && workSector.otherInput.value == "" ? false : true
        jobTitle.otherInput.isInputValid = jobCheck
        workSector.otherInput.isInputValid = workCheck
        if (!jobCheck || !workCheck) return false
        else return true
    }

    const handleSubmit = async () => {
        let hasOtherInput = true
        setIsSubmitClicked(true)
        const validateField = form
        const validateCheckbox = checkBox
        if (form.jobTitle.value == "6" || form.workSector.value == "10") {
            hasOtherInput = validateOtherInput(form.jobTitle, form.workSector)
        }
        const isfieldsValid = ValidateForm(form, validateField)
        const isCheckBoxValid = ValidateCheckBox(checkBox, validateCheckbox)

        if (!isfieldsValid.includes(false) && !isCheckBoxValid.includes(false) && hasOtherInput && recaptchaDone) {
            setIsLoading(true)
            const requestBodyObj = {
                firstName: form.firstName.value,
                bookType: bookType,
                lastName: form.lastName.value,
                jobTitle: parseInt(form.jobTitle.value),
                jobTitleOther: form.jobTitle.otherInput.value,
                company: form.establishmentName.value,
                sector: parseInt(form.workSector.value),
                sectorOther: form.workSector.otherInput.value,
                wholesaler: form.wholeSaler.value,
                email: form.email.value,
                confirmAge: checkBox.confirmationCheckbox.value ? 1 : 0,
                confirmNewsletter: checkBox.aggrementCheckbox.value ? 1 : 0,
            }
            const payload = {
                requestDomain: "www.philadelphiaprofessional.co.uk",
                requestCountry: "uk",
                requestLanguage: "en",
                requestCampaign: "PHUK200101_AFHRecipeBook_Philadelphia_Campaign",
                requestTransaction: "PHUK200101 Philadelphia AFH Recipe Book Promotion (IN)",
                requestLegalListName: "Privacy_Policy_EN",
                requestLegalTextName: "PrivacyPolicy_EN",
                "g-recaptcha-response": recaptchaValue,
                requestBody: requestBodyObj,
            }
            try {
                const res = await axios.post(formUrl, payload)
                if (res.data.toLowerCase() == "success") {
                    setIsAPIFailed(false)
                    setShowError(false)
                    setIsLoading(false)
                    setShowRecaptchaError(false)
                    formSubmission(true)
                    // success: add custom object to window datalyer obj when form is submitted
                    formSubmitObj.GAeventAction = { ...requestBodyObj, submit: "success" }
                    if (location && location.href) {
                        formSubmitObj.GAeventLabel = location.href
                    }
                    if (window !== "undefined" && window.dataLayer) {
                        return window.dataLayer.push({ ...formSubmitObj })
                    }
                }
            } catch (error) {
                setIsLoading(false)
                setShowError(false)
                setIsAPIFailed(true)
                setShowRecaptchaError(false)
                // failure: add custom object to window datalyer obj when form is submitted
                formSubmitObj.GAeventAction = { ...requestBodyObj, submit: "failure" }
                if (location && location.href) {
                    formSubmitObj.GAeventLabel = location.href
                }
                if (window !== "undefined" && window.dataLayer) {
                    return window.dataLayer.push({ ...formSubmitObj })
                }
                throw new Error(error)
            }
        } else {
            const element = document.getElementById("form")
            element.scrollIntoView({ behavior: "smooth" })
            setIsLoading(false)
            setShowError(true)
            setIsAPIFailed(false)
            if (!isfieldsValid.includes(false) && !isCheckBoxValid.includes(false) && hasOtherInput && !recaptchaDone) {
                setShowRecaptchaError(true)
            }
        }
    }

    function handleCheckboxList(event, id) {
        const validateCheckbox = validate({
            ...checkBox[id],
            value: event?.target?.checked,
        })
        setCheckBox(field => ({
            ...field,
            [id]: {
                ...field[id],
                value: event.target.checked,
                isValid: !event.target.checked ? true : validateCheckbox.isValid,
            },
        }))
    }

    const getCheckValue = (evt, id) => {
        if (id === JOB_TITLE && evt?.target?.value === "6") {
            return true
        } else if (id === WORK_SECTOR && evt?.target?.value === "10") {
            return true
        } else return false
    }

    function jobAndWorkSector(event, id, validateField) {
        const check = getCheckValue(event, id)
        mandateFields[id] = event.target.value !== "" ? true : false
        if (id == "jobTitle") {
            mandateFields[id.concat("Input")] = event.target.value !== "6" ? true : false
        }
        if (id == "workSector") {
            mandateFields[id.concat("Input")] = event.target.value !== "10" ? true : false
        }
        const setValue = { ...form[id].otherInput, isInputValid: true }
        setForm(field => ({
            ...field,
            [id]: {
                ...field[id],
                showInput: check,
                otherInput: setValue,
                value: event?.target?.value,
                isValid: validateField.isValid,
            },
        }))
    }

    function jobAndWorkInput(event, id) {
        mandateFields[id] = event.target.value !== "" ? true : false
        const ID = id.replace("Input", "")
        const setValue = { ...form[ID].otherInput, value: event.target.value }
        setForm(field => ({
            ...field,
            [ID]: { ...field[ID], otherInput: setValue },
        }))
    }

    function handleOtherFields(event, id) {
        const validateField = event.target.value !== "" && validate({ ...form[id], value: event.target.value })
        if (id === JOB_TITLE || id === WORK_SECTOR) {
            jobAndWorkSector(event, id, validateField)
        } else if (id === JOB_TITLE_INPUT || id === WORK_SECTOR_INPUT) {
            jobAndWorkInput(event, id)
        } else {
            setForm(field => ({
                ...field,
                [id]: {
                    ...field[id],
                    value: event.target.value,
                    isValid: event.target.value == "" ? true : validateField.isValid,
                },
            }))
        }
    }

    const handleChange = (event, id) => {
        if (checkboxList.includes(id)) {
            handleCheckboxList(event, id)
        } else {
            handleOtherFields(event, id)
        }
        Object.entries(mandateFields)?.map(([key, value]) => {
            if (key == id && id !== "confirmationCheckbox") {
                if (minLenFields.includes(id)) {
                    mandateFields[id] = event.target.value.length >= 2 ? true : false
                } else if (id == "email") {
                    mandateFields[id] = validateEmail(event.target.value) ? true : false
                } else {
                    mandateFields[id] = event.target.value !== "" ? true : false
                }
            }
            if (id == "confirmationCheckbox") {
                mandateFields[id] = event.target.checked
            }
        })
        if (
            mandateFields.firstName &&
            mandateFields.lastName &&
            mandateFields.jobTitle &&
            mandateFields.workSector &&
            mandateFields.email &&
            mandateFields.confirmationCheckbox &&
            mandateFields.jobTitleInput &&
            mandateFields.workSectorInput
        ) {
            setShowError(false)
        }
    }

    const handleOnBlur = (event, id) => {
        if (minLenFields.includes(id)) {
            if (event.target.value == "") {
                isSubmitClicked ? setShowError(true) : setShowError(false)
                setForm(field => ({
                    ...field,
                    [id]: { ...field[id], isValid: true },
                }))
            } else {
                const isError = event.target.value.length < 2 || !validString(event.target.value) ? true : false
                isSubmitClicked && showError ? setShowError(true) : setShowError(isError)
                setForm(field => ({
                    ...field,
                    [id]: { ...field[id], isValid: !isError },
                }))
            }
        }
        if (id === "email") {
            const validateEmail = validate({ ...form[id], value: event.target.value })
            const check = !validateEmail.isValid ? true : false
            showError ? setShowError(true) : setShowError(check)
            if (event.target.value == "") {
                isSubmitClicked ? setShowError(true) : setShowError(false)
            }
        }
    }

    function onChange(value) {
        setRecaptchaValue(value)
        value ? setRecaptchaDone(true) : setRecaptchaDone(false)
    }

    function handleOnFocus(id) {
        if (id == JOB_TITLE_INPUT || id == WORK_SECTOR_INPUT) {
            const ID = id.replace("Input", "")
            const setValue = { ...form[ID].otherInput, isInputValid: true }
            setForm(field => ({
                ...field,
                [ID]: { ...field[ID], otherInput: setValue },
            }))
        }
    }

    function renderFields(list) {
        return Object.entries(list).map(([key, value]) => (
            <RenderField
                key={key}
                id={key}
                field={value}
                onBlur={handleOnBlur}
                handleChange={handleChange}
                onFocus={handleOnFocus}
                variant={variant}
                error=""
            />
        ))
    }
    return (
        <div id="form" className="bg-primary py-45 px-10">
            <div className={formTitleWrap}>
                <h3 className={formTitle}>{<Typography content={heading}></Typography>}</h3>
                <div className={formDesc}>{<Typography content={description}></Typography>}</div>
            </div>
            {showError && (
                <div className="bg-white text-lg leading-7 my-11 mx-auto p-30 text-center w-[469px] max-w-[90%] text-pureRed">
                    {submit.error}
                </div>
            )}
            {isAPIFailed && (
                <div className="bg-white text-lg leading-7 my-11 mx-auto p-30 text-center w-[469px] max-w-[90%] text-pureRed">
                    {API_ERROR_MSG}
                </div>
            )}
            {isLoading && (
                <div className="loader">
                    {" "}
                    <ModalWrapper>
                        <LoaderIcon />
                    </ModalWrapper>
                </div>
            )}
            <div className="lg:mx-auto md:mx-auto my-0 lg:w-[945px] md:w-[440px] lg:grid lg:grid-cols-2 gap-x-37 gap-y-20 phillyFiveForm">
                {renderFields(form)}
            </div>
            <div className="lg:flex mx-auto my-20 lg:w-[945px] w-[440px] max-w-full phillySubmit">
                <div className="lg:w-[454px]">{renderFields(checkBox)}</div>
                <div className="mx-auto my-0 child:flex child:justify-center lg:child:justify-start captacha">
                    <ReCAPTCHA sitekey={siteKey} onChange={e => onChange(e)} />
                    {ShowRecaptchaError && <p className="text-pureRed">{reCaptchaError}</p>}
                </div>
            </div>
            <div>
                <p className="text-white text-center opacity-50 mt-20 mb-50">{MANDATORY}</p>
            </div>
            <Button
                text={submit.label}
                onClick={handleSubmit}
                className={style?.btnStyle}
                gaEventClass={gaEventClasses.button_click}
                dataActionDetail={"submit"}
            />
        </div>
    )
}

Form.propTypes = {
    description: PropTypes.string,
    heading: PropTypes.string,
    bookType: PropTypes.number,
    formSubmission: PropTypes.func,
    jsonEditor: PropTypes.any,
}

export default Form
