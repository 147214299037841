import React from "react"
import PropTypes from "prop-types"
import ResponsiveImage from "../ResponsiveImage/ResponsiveImage"
import * as styles from "./ImageCard-tw-styles"
import Typography from "../Typography/Typography"
import Link from "../Link/Link"

const ImageCard = props => {
    const { text, title, images, link, variant, gaEventClass, gaEventLabel } = props

    const style = styles[variant] || styles.defaultStyle

    return (
        <>
            <div className={style?.imageBoxWraper}>
                <div className={style?.imageBoxWrpr}>
                    <div className={style?.imgContainer}>
                        <ResponsiveImage
                            contentfulImagesComponent={images}
                            className={style?.imgWrpr}
                            eventClass={gaEventClass}
                            eventLabel={gaEventLabel}
                        />
                    </div>
                    <div className={style?.contentWrapr}>
                        {title && <Typography className={style?.heading} content={title} />}
                        {text && <Typography className={style?.description} content={text} />}
                        {link?.label && <Link {...link} className={style?.linkWrpr} />}
                    </div>
                </div>
            </div>
        </>
    )
}

ImageCard.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.object,
    image: PropTypes.object,
    images: PropTypes.object,
    variant: PropTypes.string,
    gaEventClass: PropTypes.string,
    gaEventLabel: PropTypes.string,
}

export default ImageCard
