const { fragment: ResponsiveImageFragment } = require("../UI/ResponsiveImage/ResponsiveImage.fragment")
const { fragment: RichtextFragment } = require("../RichTextModel/RichTextModel.fragment")

const fragment = `
    heading
    description
    richtextDescription {
        ${RichtextFragment}
    }
    underlineText
    images {
        ${ResponsiveImageFragment}
    }
    slideLayoutVariant
    variant
    slideLayoutVariant
    cta {
        url
        label
        title
        target
        scrollToElementId
        gaEventClass
        gaEventLabel
    }
    sectionId
`
module.exports = { fragment }
