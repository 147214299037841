import React from "react"
import PropTypes from "prop-types"

import { defaultStyle as style } from "./HomeHeroCtaWithoutTextLayout-tw-styles"
import Link from "../../../UI/Link/Link"

const HomeHeroCtaWithoutTextLayout = props => {
    const { cta } = props

    return (
        <div className={style.content}>
            <Link className={style.cta} {...cta} />
        </div>
    )
}

HomeHeroCtaWithoutTextLayout.propTypes = {
    cta: PropTypes.object,
}

export default HomeHeroCtaWithoutTextLayout
