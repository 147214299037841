import React from "react"
import PropTypes from "prop-types"
import ProductCard from "../UI/ProductCard/ProductCard"
import { v4 as uuidv4 } from "uuid"
import Slider from "react-slick"
import * as tw from "../../../tailwind.config"
import CustomPaging from "../UI/CustomPaging/CustomPaging"

function ProductCardsSlider({ products, variant }) {
    const customPagingVariant = "darkRed"
    const settings = {
        arrows: false,
        swipe: true,
        infinite: false,
        rows: 1,
        className: "product-cards-slider",
        autoplay: false,
        dots: true,
        variableWidth: true,
        centerMode: true,
        dotsClass: "slick-dots-wrapper bottom-[13.5px]",
        customPaging: () => {
            return <CustomPaging variant={customPagingVariant} />
        },
        responsive: [
            {
                breakpoint: 9999,
                settings: {
                    slidesToShow: Math.min(products.length, 4),
                    centerMode: false,
                    infinite: false,
                    variableWidth: false,
                },
            },
            {
                breakpoint: Number(tw.theme.screens.xlg.slice(0, -2)) - 1,
                settings: {
                    slidesToShow: Math.min(products.length, 4),
                    centerMode: false,
                    infinite: false,
                    variableWidth: false,
                },
            },
            {
                breakpoint: Number(tw.theme.screens.lg.slice(0, -2)) - 1,
                settings: {
                    slidesToShow: Math.min(products.length, 2),
                    centerMode: true,
                    infinite: true,
                    variableWidth: true,
                },
            },
            {
                breakpoint: Number(tw.theme.screens.md.slice(0, -2)) - 1,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    infinite: false,
                    variableWidth: true,
                },
            },
        ],
    }
    return (
        <div>
            <Slider {...settings}>
                {products.map(product => {
                    return <ProductCard product={product} variant={variant} key={uuidv4()} />
                })}
            </Slider>
        </div>
    )
}

export default ProductCardsSlider

ProductCardsSlider.propTypes = {
    products: PropTypes.array,
    variant: PropTypes.string,
}
