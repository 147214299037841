import * as rules from "../constants/rules.constants"

export const validateRequired = value => !(!value || value.trim() === "")

export const validateCheckbox = value => !!value

export const validateEmail = value => {
    return !!new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(value)
}

export const validatePassword = value => {
    return !!new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/).test(value)
}

export const validateLength = (value, length) => value.toString().length > length
function handleReqType(validateField, rule) {
    validateField.error = !validateRequired(validateField.value) ? rule.errorMessage : ""
    validateField.isValid = validateField.error === "" ? true : false
}

export function validString(str) {
    return !!new RegExp(/^[a-zA-Z0-9 ]*$/).test(str)
}

function handleEmailRule(validateField, rule) {
    if (validateField.value !== "") {
        validateField.error = !validateEmail(validateField.value) ? rule.errorMessage : ""
        validateField.isValid = validateField.error === "" ? true : false
    }
}

function handleChekboxRule(validateField, rule) {
    validateField.error = !validateCheckbox(validateField.value) ? rule.errorMessage : ""
    validateField.isValid = validateField.error === "" ? true : false
}

function handlePasswordRule(validateField, rule) {
    if (validateField.value !== "" && validateField.value.length > validateField.length) {
        validateField.error = !validatePassword(validateField.value) ? rule.errorMessage : ""
        validateField.isValid = validateField.error === "" ? true : false
    }
}

function handleLengthRule(validateField, rule) {
    if (validateField.value !== "") {
        validateField.error = !validateLength(validateField.value, validateField.length) ? rule.errorMessage : ""
        validateField.isValid = validateField.error === "" ? true : false
    }
}

function handleStringRule(validateField, rule) {
    validateField.error = !validString(validateField.value) ? rule.errorMessage : ""
    validateField.isValid = validateField.error === "" ? true : false
}

export const validate = field => {
    const validateField = field
    if (validateField.rules && validateField.rules.length > 0) {
        validateField.rules.forEach(rule => {
            switch (rule.type) {
                case rules.REQUIRED:
                    handleReqType(validateField, rule)
                    break
                case rules.EMAIL:
                    handleEmailRule(validateField, rule)
                    break
                case rules.CHECKBOX:
                    handleChekboxRule(validateField, rule)
                    break
                case rules.PASSWORD:
                    handlePasswordRule(validateField, rule)
                    break
                case rules.LENGTH:
                    handleLengthRule(validateField, rule)
                    break
                default:
                    break
            }
        })
    }

    return validateField
}
