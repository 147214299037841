export const defaultStyles = {
    wrapper: "",
    content: "section-wrapper",
}

export const WRAPPED = {
    wrapper: "",
    content: "section-wrapper m-auto mt-[68px]",
}

export const BEIGE_COLOURED = {
    wrapper: "",
    content: "m-auto py-16 bg-beige flex justify-center",
}

export const COOKIES_SNIPPET = {
    wrapper: "bg-beige",
    content:
        "section-wrapper py-2 [&_*]:!bg-beige [&_p]:!font-medium [&_p]:!font-poppins [&_p]:!text-[17px] [&_p]:!tracking-normal [&_p]:!leading-[150%] [&_p]:!text-black",
}
