const algoliasearch = require("algoliasearch")
const { algoliaAPI } = require('../constants/thirdParty.constants')

const getRecipeSearchResults = async (params, pageLanguage) => {
    const { search, hitsPerPage, cookingType, occasion, special, analytics = true } = params
    try {
        const algoliaParams = algoliaAPI(process.env.GATSBY_ACTIVE_ENV === "production").find(
            item => item.pageLanguage === pageLanguage
        )

        if (!algoliaParams) {
            throw new Error(`No Algolia index found for language: ${pageLanguage}`)
        }

        const term = search || ""
        const client = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, algoliaParams.searchKey)
        const index = client.initIndex(algoliaParams.recipesIndex)

        const facetFilters = []
        if (cookingType) {
            facetFilters.push([`cookingType.Id:${cookingType}`])
        }
        if (occasion) {
            facetFilters.push([`occasion.Id:${occasion}`])
        }
        if (special) {
            facetFilters.push([`special.Id:${special}`])
        }
        const options = {
            hitsPerPage: hitsPerPage,
            facetFilters: facetFilters.length > 0 ? facetFilters : "",
            facets: ["*"],
            analytics: analytics,
        }
        const result = await index.search(term, {
            ...options,
        })
        return result
    } catch (error) {
        return { error: error.message }
    }
}

module.exports = { getRecipeSearchResults }
