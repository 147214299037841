import React, { useId, useState, useEffect } from "react"
import PropTypes from "prop-types"
import * as styles from "./RecipesSearchInput-tw-styles"
import Search from "../../assets/icons/24x24/Search"
import { useRecipe } from "../../context/RecipeProvider"
import { gaEventClasses } from "../../constants/gtm.constants"
import { pageTypes } from "../../constants/pageType.constants"

const icons = {
    Search: <Search />,
}

function RecipesSearchInput({ label = "Search for a recipe", icon, pageType }) {
    const style = styles.defaultStyle
    const id = useId()
    const finalIcon = icons[icon] || null
    const { query, handleSearchInputChange, handleClearFilters } = useRecipe()
    const [isFocused, setIsFocused] = useState(false)
    const [isErrored, setIsErrored] = useState(false) // Not sure if we need this
    const [inputValue, setInputValue] = useState("")

    useEffect(() => {
        setInputValue(query)
    }, [query])

    const handleFocus = () => {
        setIsFocused(true)
    }

    const handleBlur = () => {
        setIsFocused(false)
    }

    const handleChange = e => {
        setInputValue(e.target.value)
    }

    const handleButtonClick = (e = {}) => {
        handleClearFilters()
        handleSearchInputChange(inputValue)

        if (window?.dataLayer) {
            const isRecipePage = pageType === pageTypes.recipeDetail

            window.dataLayer.push({
                event: "MdlzcustomEvent",
                GAeventCategory: isRecipePage ? "searchRecipe" : "searchHomepage",
                GAeventAction: "enter or clickButton",
                GAeventLabel: inputValue,
            })
        }
    }

    const handleKeyDown = e => {
        if (e.keyCode === 13) {
            handleButtonClick(e)
        }
    }

    const hasValue = inputValue !== ""

    return (
        <div className={style.wrapper}>
            <input
                className={`${style.input} ${isErrored ? "text-burgundy" : "text-black"}`}
                type="text"
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                value={inputValue}
                onKeyDown={handleKeyDown}
                id={id}
            />
            <label
                htmlFor={id}
                className={`${style.label} ${
                    hasValue || isFocused ? "-translate-y-4 tag !pointer-events-none" : "translate-y-0 body-m"
                } ${isErrored ? "text-burgundy" : ""}`}
            >
                {label}
            </label>
            {finalIcon && (
                <button
                    type="button"
                    aria-label={label}
                    className={`${style.icon} ${
                        isErrored ? "rect-descenders:fill-burgundy circle-descenders:stroke-burgundy " : ""
                    }`}
                    onClick={e => handleButtonClick(e)}
                >
                    {finalIcon}
                </button>
            )}
        </div>
    )
}

export default RecipesSearchInput

RecipesSearchInput.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.oneOf([null, "Search"]),
    pageType: PropTypes.string,
}
