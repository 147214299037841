import React from "react"
import PropTypes from "prop-types"
import Link from "../UI/Link/Link"

const MEDIA_TYPES = {
    VIDEO: "VIDEO",
    IMAGE: "IMAGE",
    CAOURSEL_ALBUM: "CAROUSEL_ALBUM",
}

const getImageUrl = post => {
    switch (post?.media_type) {
        case MEDIA_TYPES.VIDEO:
            return post?.thumbnail_url
        case MEDIA_TYPES.IMAGE:
        case MEDIA_TYPES.CAOURSEL_ALBUM:
            return post?.media_url
        default:
            return post?.media_url
    }
}
const FeedCard = ({ post, style }) => {
    const imageUrl = getImageUrl(post)
    const imageWidth = 343 // Max width for xlg
    const imageHeight = imageWidth

    return (
        <Link url={post?.permalink} className={style?.feedCard}>
            <img
                className={style?.feedCardImage}
                src={imageUrl}
                alt={post?.caption || "Instagram post"}
                width={imageWidth}
                height={imageHeight}
                loading="lazy"
            />
        </Link>
    )
}
FeedCard.propTypes = {
    post: PropTypes.object,
    style: PropTypes.object,
}

export default FeedCard
