import React from "react"
import PropTypes from "prop-types"

import { defaultStyle as style } from "./HomeHeroCtaWithTextLayout-tw-styles"

import RichTextModel from "../../../RichTextModel/RichTextModel"
import Link from "../../../UI/Link/Link"

const HomeHeroCtaWithTextLayout = props => {
    const { cta, description } = props
    return (
        <div className={style.content}>
            <div className={style.heading}>
                <RichTextModel richText={description} />
            </div>
            <Link className={style.cta} {...cta} />
        </div>
    )
}

HomeHeroCtaWithTextLayout.propTypes = {
    cta: PropTypes.object,
    description: PropTypes.object,
}

export default HomeHeroCtaWithTextLayout
