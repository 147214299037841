const { fragment } = require("./RichTextModel.fragment")

const query = (id, isPreview) => `
{
  richTextModel(id: "${id}", preview: ${isPreview}) {
        name
        variant
        title
        richText{
          ${fragment}
        }
        link {
          label
          title
          url
          target
          scrollToElementId
          gaEventClass
          gaEventLabel
        }
  }
}
`
module.exports = { query }
