const { fragment: ResponsiveImageFragment } = require("../../UI/ResponsiveImage/ResponsiveImage.fragment")

const query =  (isPreview, locale) => `{
  subscriptionFormCollection(preview: ${isPreview}, limit: 1, locale: "${locale}") {
    items {
        active,
        internalTitle,
        heading,
        description,
        sectionId,
        image {
            ${ResponsiveImageFragment}
        }
        newsletterConfirmation
        newsletterConfirmation2
        thirdPartyConfirmation
        thirdPartyConfirmation2
        bannerTitle
        bannerDescription
        bannerPrimaryCtaLabel
        bannerSecondaryCtaLabel
    }
  }
}
`

module.exports = { query }
