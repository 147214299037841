import * as tw from "../../../tailwind.config"

export const defaultStyle = {
    wrapper: "relative bg-red",
    container: "section-wrapper flex flex-col items-center gap-8 pt-8 pb-16 md:pt-16 md:pb-20 xlg:pt-24 xlg:pb-32",
    title: "headline3 text-white title-shadow text-center",
    searchWrapper: "relative self-stretch w-full max-w-[520px] mx-auto lg:max-w-[unset] lg:mx-[unset]",
    dropdownsWrapper: "w-full max-w-[520px] flex flex-col lg:flex-row lg:max-w-[unset] justify-around gap-4 lg:gap-8",
    selectWrapper: "w-full text-white font-light flex flex-col",
    cta: "block button-link text-center event_button_click",
}

export const selectStyles = {
    control: (provided, state) => {
        const allOptionsDisabled = state.options.every(option => option.isDisabled)
        return {
            ...provided,
            backgroundColor: tw.theme.extend.colors.darkRed,
            height: "58px",
            borderRadius: state.menuIsOpen ? "15px 15px 0 0" : "15px",
            borderColor: tw.theme.extend.colors.black,
            borderWidth: "1px",
            outline: "none",
            boxShadow: "none",
            cursor: "pointer",
            ":hover": {
                borderColor: tw.theme.extend.colors.black,
            },
            opacity: allOptionsDisabled ? 0.8 : 1,
            pointerEvents: allOptionsDisabled ? "none" : "auto",
        }
    },
    menu: provided => ({
        ...provided,
        margin: 0,
        backgroundColor: tw.theme.extend.colors.red,
    }),
    menuList: provided => ({
        ...provided,
        padding: 0,
        maxHeight: "unset",
    }),
    option: (provided, state) => {
        const defaultStyles = {
            ...provided,
            height: "58px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "17px",
            fontWeight: "normal",
            lineHeight: "150%",
            fontWeight: "bold",
            backgroundColor: tw.theme.extend.colors.burgundy,
            color: tw.theme.extend.colors.white,
            borderColor: tw.theme.extend.colors.black,
            borderTopColor: "transparent",
            cursor: "pointer",
            borderWidth: "1px",
            ":active": {
                backgroundColor: tw.theme.extend.colors.burgundy,
            },
            ":hover": {
                backgroundColor: tw.theme.extend.colors.red,
            },
        }
        if (state.isDisabled) {
            return {
                ...defaultStyles,
                display: "none",
            }
        }
        if (state.isSelected) {
            return {
                ...defaultStyles,
                backgroundColor: tw.theme.extend.colors.beige,
                color: tw.theme.extend.colors.darkGrey,
                ":hover": {
                    backgroundColor: tw.theme.extend.colors.beige,
                },
            }
        }

        return defaultStyles
    },
    singleValue: provided => ({
        ...provided,
        fontFamily: "Poppins",
        fontSize: "17px",
        lineHeight: "150%",
        fontWeight: "bold",
        color: tw.theme.extend.colors.white,
        textAlign: "center",
        marginLeft: "36px",
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
        color: tw.theme.extend.colors.white,
        " svg": {
            fill: tw.theme.extend.colors.white,
        },
    }),
    indicatorSeparator: provided => ({
        ...provided,
        display: "none",
    }),
}
