const query = (id, isPreview) => `{
  waveDivider(id: "${id}", preview: ${isPreview}) {
    internalTitle
    direction
    color
  }
}
`

module.exports = { query }
