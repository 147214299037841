import React from "react"
import PropTypes from "prop-types"
import Slider from "react-slick"
import { v4 as uuidv4 } from "uuid"
import { defaultStyle as style } from "./HomeHeroSlider-tw-styles"
import { get } from "../../../helpers/utils"

import ResponsiveImage from "../../UI/ResponsiveImage/ResponsiveImage"
import HomeHeroCtaWithoutTextLayout from "./HomeHeroCtaWithoutTextLayout/HomeHeroCtaWithoutTextLayout"
import HomeHeroCtaWithTextLayout from "./HomeHeroCtaWithTextLayout/HomeHeroCtaWithTextLayout"
import CustomPaging from "../../UI/CustomPaging/CustomPaging"

const LAYOUT_VARIANT = {
    CTA_WITHOUT_TEXT: "CTA_WITHOUT_TEXT",
    CTA_WITH_TEXT: "CTA_WITH_TEXT",
}

const HomeHeroSlider = props => {
    const { slidesCollection, sliderSettings } = props
    const slides = get(slidesCollection, "items", [])

    const customPagingVariant = "yellow"
    const settings = {
        className: "home-hero-slider",
        infinite: true,
        speed: 500,
        slidesToShow: sliderSettings?.slidesToShowTab || 1,
        slidesToScroll: sliderSettings?.slidesToScrollTab || 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        dots: true,
        dotsClass: "slick-dots-wrapper",
        customPaging: () => {
            return <CustomPaging variant={customPagingVariant} />
        },
    }

    const renderHeroLayout = slide => {
        switch (slide.slideLayoutVariant) {
            case LAYOUT_VARIANT.CTA_WITHOUT_TEXT:
                return <HomeHeroCtaWithoutTextLayout cta={slide?.cta} />
            case LAYOUT_VARIANT.CTA_WITH_TEXT:
                return <HomeHeroCtaWithTextLayout cta={slide?.cta} description={slide?.richtextDescription} />
            default:
                return <div>Default slide layout</div>
        }
    }

    const renderSlides = slides => {
        return slides.map(slide => (
            <div className={style.slideItem} key={uuidv4()}>
                <div className={style.overlay}>{renderHeroLayout(slide)}</div>
                <ResponsiveImage contentfulImagesComponent={slide.images} className={style.bg} />
            </div>
        ))
    }

    return (
        <div className={style?.wrapper}>
            <Slider {...settings}>{renderSlides(slides)}</Slider>
        </div>
    )
}

HomeHeroSlider.propTypes = {
    slideLayoutVariant: PropTypes.string,
    slidesCollection: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    sliderSettings: PropTypes.object,
}

export default HomeHeroSlider
