export const defaultStyle = {
    layoutContainer: "imagelist max-w-full relative lg:mx-auto my-0 ",
    heading:
        "text-center font-ProxNarrow lg:text-4xl text-[40px] lg:w-full w-2/3 mx-auto leading-[50px] text-white pb-40 tracing-[1px] [&>p]:text-[40px]",
    description:
        "chefDesc text-2xl md:w-[750px] mx-auto my-0 lg:text-3xl leading-[30px] lg:leading-10 text-center text-white px-10",
    anchor: "anchor",
    listItem: "listItem lg:w-[360px] lg:max-w-full even:pt-[0%]  mx-auto px-[2px] lg:px-auto overflow-hidden",
    list: " zigZagImg max-w-full lg:w-[1170px] w-full mx-auto  max-w-full grid md:grid-cols-3 md:gap-13 gap-10 lg:gap-[24px] grid-cols-2 md:px-23  lg:mx-auto md:mx-0 px-15 md:px-auto relative mt-10 lg:max-h-[860px]",
    textContainer: "bg-primary py-30 text-center ",
    imgMain: "imgMain rounded-[1rem]",
}
