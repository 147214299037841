import React from "react"
import PropTypes from "prop-types"
import Typography from "../UI/Typography/Typography"
import ResponsiveImage from "../UI/ResponsiveImage/ResponsiveImage"

const ImageHeroBanner = props => {
    const { description, heading, style, underlineText, images, sectionId } = props
    const { wrapper, imgWrapper, img, contentWrapper, underlineTextWrapper, headingWrapper, descriptionWrapper } = style
    return (
        <div className={wrapper} id={sectionId ?? null}>
            {!!images && (
                <div className={imgWrapper}>
                    <ResponsiveImage contentfulImagesComponent={images} className={img} />
                </div>
            )}

            <div className={contentWrapper}>
                {underlineText && <div className={underlineTextWrapper}>{underlineText}</div>}
                {heading && (
                    <div className={headingWrapper}>
                        <Typography content={heading} />
                    </div>
                )}
                {description && (
                    <div className={descriptionWrapper}>
                        <Typography content={description} />
                    </div>
                )}
            </div>
        </div>
    )
}

ImageHeroBanner.propTypes = {
    heading: PropTypes.string,
    description: PropTypes.string,
    variant: PropTypes.string,
    underlineText: PropTypes.string,
    images: PropTypes.object,
    style: PropTypes.object,
    sectionId: PropTypes.string,
}

export default ImageHeroBanner
