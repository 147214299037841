import React from "react"
import PropTypes from "prop-types"
import * as styles from "./ProductCard-tw-styles"
import ResponsiveImage from "../ResponsiveImage/ResponsiveImage"
import { labels as productLabels } from "../../../constants/product.constants"
import { convertToSecureUrl } from "../../../helpers/utils"

function ProductCard({ product, variant }) {
    const style = styles?.[variant] || styles?.defaultStyle
    const productImageUrl =
        product?.ProductImage?.find(image => image?.type === productLabels.CARD_PHOTO_TYPE)?.url ||
        product?.ProductImage?.[0]?.url ||
        productLabels.PRODUCT_IMAGE_FALLBACK_URL

    return (
        <div className={style?.wrapper}>
            <div className={style?.imageWrapper}>
                <ResponsiveImage
                    contentfulImagesComponent={{
                        imageDesktop: { url: convertToSecureUrl(productImageUrl) },
                    }}
                    alt={product?.ProductName}
                    className={style?.img}
                />
            </div>
            <h4 className={style?.title} title={product?.ProductName}>
                {product?.ProductName}
            </h4>
        </div>
    )
}

export default ProductCard

ProductCard.propTypes = {
    product: PropTypes.object,
    variant: PropTypes.string,
}
