const query = (id, isPreview) => `{
  featuredRecipesSection(id: "${id}", preview: ${isPreview}) {
    internalTitle
    title
    recipeIdsCollection(limit: 6) {
        items {
            value
        }
    }
    searchTitle
    searchLabel
    variant
    cta {
        label
        title
        url
        target
        scrollToElementId
        gaEventClass
        gaEventLabel
    }
  }
}
`

module.exports = { query }
