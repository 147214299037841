export const ReceipeListStyles = {
    stepsContainer: "stepsContainer flex flex-wrap my-60 px-20 lg:px-auto justify-center",
    olClass: "list-decimal w-full lg:w-[54%] md:w-[77%] mx-auto",
    liDescription: "liDescription inline-flex items-center md:w-[81.333%] w-3/4 ml-[20px] text-[18px]",
    roundedHeading:
        "bg-liBgRound reciepeLi bg-contain bg-no-repeat md:h-[78px]  md:w-[78px] h-[35px] w-[42px] font-ProxBold md:text-[45px] text-[30px] flex  items-center justify-center w-[10%]",
    receipeHeading:
        "receipeHeading text-darkBlue text-[30px] font-ProxBold w-full mx-auto flex justify-center my-0 leading-[30px] tracking-[1px]",
    receipeSubheading:
        "subheading text-darkBlue text-[30px] font-ProxBold w-full justify-center mx-auto flex text-center mt-[4.5px] mb-0 leading-[30px]",
    receipeMainWrpr: "receipeMainWrpr lg:w-[1170px] w-full mx-auto flex flex-wrap",
    headingWrpr: "flex mx-auto flex-wrap mt-40 w-[615px]",
}
