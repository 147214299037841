const query = (isPreview, locale) => `{
  footerCollection(preview: ${isPreview}, limit: 1, locale: "${locale}") {
    items {
      logo {
        title
        description
        url
        width
        height
      }
      mainLinksCollection(limit:2) {
        items {
            label
            linksCollection {
                items {
                    label
                    title
                    url
                    target
                    scrollToElementId
                    gaEventClass
                    gaEventLabel
                }
            }
        }
      }
      socialLinksCollection(limit:1) {
        items {
            label
            linksCollection {
                items {
                    label
                    title
                    url
                    target
                    scrollToElementId
                    gaEventClass
                    gaEventLabel
                    image {
                        url
                        title
                        description
                        width
                        height
                    }
                }
            }
        }
      }
      copyRight
      brandLogo {
        title
        description
        url
        width
        height
      }
    }
  }
}
  `
module.exports = { query }
