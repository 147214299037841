export const defaultStyle = {
    wrapper: "hidden",
}
export const BEIGE_BG = {
    wrapper: "relative bg-beige pb-2",
    container:
        "relative section-wrapper flex flex-col items-center gap-8 py-8 md:py-10 lg:py-12 xlg:pt-[60px] xlg:pb-[117px]",
    cardsWrapper: "relative flex gap-5 flex-wrap justify-center",
    loadMoreWrapper: "w-full flex justify-center",
}
