import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import { selectStyles } from "./RecipeFiltersSection-tw-styles"
import { labels as recipeLabels } from "../../constants/recipe.constants"
import { useRecipe } from "../../context/RecipeProvider"
import { useGlobalContext } from "../../context/GlobalContext"

function SelectWrapper({ recipeFilters, filter }) {
    const { language } = useGlobalContext()
    const localizedLabels = recipeLabels[language] || recipeLabels["es"] 
    const {
        availableSubCategories,
        handleFilterChange,
        filters: selectedFilters,
        getOptionsExceptCurrentFilter,
    } = useRecipe()

    const optionsInitial = recipeFilters[filter].map(item => ({
        value: item.CategoryId,
        label: item.CategoryName,
        isDisabled: !availableSubCategories[filter][item.CategoryId],
    }))
    const selectRef = useRef()
    const [options, setOptions] = useState(optionsInitial)

    const selectedOption = options.find(option => option.value === selectedFilters[filter])

    const onMenuOpen = () => {
        // On menu open, we want to update the options to reflect the current state of the availableSubCategories
        const asyncWrapper = async () => {
            const exceptCurrentFilter = await getOptionsExceptCurrentFilter(filter)
            const newOptions = recipeFilters[filter].map(item => ({
                value: item.CategoryId,
                label: item.CategoryName,
                isDisabled: !exceptCurrentFilter[filter][item.CategoryId],
            }))
            setOptions(newOptions)
        }
        if (!selectedOption) return
        asyncWrapper()
    }

    return (
        <Select
            ref={selectRef}
            styles={selectStyles}
            options={options}
            value={selectedOption}
            onChange={selectedOption => handleFilterChange(filter, selectedOption)}
            isSearchable={false}
            isMulti={false}
            isClearable={false}
            defaultValue={{
                value: "",
                label: localizedLabels[filter],
            }}
            onMenuOpen={onMenuOpen}
        />
    )
}

export default SelectWrapper

SelectWrapper.propTypes = {
    recipeFilters: PropTypes.object,
    filter: PropTypes.string,
}
