import React from "react"
import PropTypes from "prop-types"
import Input from "../components/UI/Input/Input"
import { PASSWORD } from "../constants/rules.constants"
import CheckBox from "../components/UI/Input/CheckBox"
import Select from "../components/UI/Input/Select"

const RenderField = props => {
    const { id, field, handleChange, onBlur, onFocus, hidePassword, error, variant } = {
        ...props,
    }

    function checkType() {
        if ((field.name === PASSWORD && hidePassword) || field.name !== PASSWORD) {
            return field.type
        } else {
            return "text"
        }
    }

    if (field.fieldType === "input") {
        return (
            <Input
                id={id}
                type={checkType()}
                placeholder={field.placeholder}
                onBlur={onBlur}
                name={field.name}
                label={field.label}
                isRequired={field.isRequired}
                onChange={handleChange}
                errorMessage={error[field.name]}
                value={field.value}
                isValid={field.isValid}
                button={field.button}
                variant={variant}
                maxLength={field.maxLength}
            />
        )
    }
    if (field.fieldType === "checkbox") {
        return (
            <CheckBox
                id={id}
                name={field.name}
                label={field.label}
                onChange={handleChange}
                value={field.value}
                errorMessage={error[field.name]}
                isValid={field.isValid}
                variant={variant}
            />
        )
    }
    if (field.fieldType === "select") {
        return (
            <Select
                options={field.options}
                id={id}
                label={field.label}
                name={field.name}
                onChange={handleChange}
                isRequired={field.isRequired}
                placeholder={field.placeholder}
                showInput={field.showInput}
                onFocus={onFocus}
                input={field.showInput ? field.otherInput : null}
                selectedValue={field.value}
                isValid={field.isValid}
                variant={variant}
            />
        )
    }
}

RenderField.propType = {
    id: PropTypes.string,
    field: PropTypes.object,
    handleChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    hidePassword: PropTypes.bool,
    error: PropTypes.object,
    variant: PropTypes.string,
}

export default RenderField
