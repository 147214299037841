import React from "react"
import PropTypes from "prop-types"
import Link from "../UI/Link/Link"
import { get } from "../../helpers/utils"
import { v4 as uuidv4 } from "uuid"

const HeroAnchorsSection = props => {
    const { style, title, subtitle, anchorsCollection } = props
    const anchors = get(anchorsCollection, "items", [])
    return (
        <div className={style?.wrapper}>
            <div className={style?.container}>
                <h2 className={style?.title}>{title}</h2>
                <p className={style?.subtitle}>{subtitle}</p>
                <div className={style?.anchors}>
                    {anchors.map(anchor => {
                        return <Link className={style?.anchor} key={uuidv4()} {...anchor} />
                    })}
                </div>
            </div>
        </div>
    )
}

HeroAnchorsSection.propTypes = {
    style: PropTypes.object,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    anchorsCollection: PropTypes.object,
}

export default HeroAnchorsSection
