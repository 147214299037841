const { fragment: ImageHeroBannerFragment } = require("../ImageHeroBanner/ImageHeroBanner.fragment")

const query = (id, isPreview) => `{
  heroSlider(id: "${id}", preview: ${isPreview}) {
    internalTitle
    variant
    sliderSettings {
        slidesToShowMobile
        slidesToScrollMobile
        slidesToShowTab
        slidesToScrollTab
    }
    slidesCollection(limit: 10) {
      items {
        ${ImageHeroBannerFragment}
      }
    }
  }
}
`

module.exports = { query }
