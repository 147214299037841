export const defaultStyle = {
    richTextWrapper: "richTextWrapper",
    title: "headline1",
    heading1: "headline1",
    heading2: "headline2",
    heading3: "headline3",
    heading4: "headline4",
    heading5: "headline5",
}

export const TERMS_CONDITIONS = {}

export const PAGE_404 = {}

export const COOKIE = {
    richTextWrapper: "richTextWrapper bg-beige pt-[120px] pb-[30px]",
    richTextContent: "section-wrapper",
    title: "headline1 text-center mb-[48px]",
    heading1: "headline1",
    heading2: "headline2",
    heading3: "headline3",
    heading4: "headline4",
    heading5: "headline5",
    paragraph: "text-left body-m font-medium",
    ulList: "list-disc list-inside ml-4 py-2",
    olList: "list-decimal list-inside [&>li]:mb-[26px]",
    list: "text-left body-m font-medium [&>ul]:px-10 [&>ul]:list-disc",
}

export const COOKIE_THIRD_PART = {
    richTextWrapper: "richTextWrapper bg-beige pt-[60px] pb-0",
    richTextContent: "section-wrapper",
    title: "headline1 text-center mb-[48px]",
    heading1: "headline1",
    heading2: "headline2",
    heading3: "headline3",
    heading4: "headline4",
    heading5: "headline5",
    paragraph: "text-left body-m font-medium",
    ulList: "list-disc list-inside ml-4 py-2",
    olList: "list-decimal list-inside [&>li]:mb-[26px]",
    list: "text-left body-m font-medium [&>ul]:px-10 [&>ul]:list-disc",
}
